<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <app-button-delete v-if="pollBlock.id" @deleteRecord="deleteRecord"></app-button-delete>
              <app-button-close route-name="pollBlock_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <section class="content form-horizontal">
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-body">
              <input type="hidden" v-model="pollBlock.id">
              <input type="hidden" v-model="pollBlock.poll">

              <app-input
                v-model="pollBlock.title"
                @blur="$v.pollBlock.title.$touch()"
                :error="$v.pollBlock.title.$error"
                id="pollBlock_title"
                :label="$t('pollBlock.title')"
              >
              </app-input>
              <app-input
                v-model.trim="pollBlock.identifier"
                @blur="$v.pollBlock.identifier.$touch()"
                :error="pollBlockIdentifierShowError"
                id="pollBlock_identifier"
                :label="$t('pollBlock.identifier') + ' (' + $t('pollBlock.identifier_validation') + ')'"
              >
              </app-input>
              <app-select
                v-model="pollBlock.site"
                :options="sites"
                id="pollBlock_site"
                :label="$t('pollBlock.site')"
              >
              </app-select>
            </div>
          </div>
        </div>
      </section>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import PollBlockModel from '../../model/PollBlockModel'
import Input from '../../components/form/inputs/Input'
import Select from '../../components/form/select/Select'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import ButtonDelete from '../../components/shared/ButtonDelete'
import ButtonClose from '../../components/shared/ButtonClose'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'PollBlockNewView',
  data () {
    return {
      tags: [],
      dataLoaded: false,
      isLoading: false,
      pollBlock: this._.cloneDeep(PollBlockModel),
      findIdentifierTimeout: null,
      isIdentifierValid: false,
      oldIdentifier: null
    }
  },
  computed: {
    pollBlockIdentifier () {
      return this.pollBlock.identifier
    },
    pollBlockIdentifierShowError () {
      return this.$v.pollBlock.identifier.$error || (this.$v.pollBlock.identifier.$dirty && !this.isIdentifierValid)
    },
    sites () {
      return this.$store.getters['site/all']
    }
  },
  watch: {
    pollBlockIdentifier (newValue) {
      this.isIdentifierValid = false
      if (this.findIdentifierTimeout) {
        clearTimeout(this.findIdentifierTimeout)
        this.findIdentifierTimeout = null
      }
      this.findIdentifierTimeout = setTimeout(() => {
        this.$store.dispatch('pollBlock/fetchByIdentifier', newValue)
          .then(() => {
            this.isIdentifierValid = false
            if (this.oldIdentifier === null) {
              // create
              if (this.$store.getters['pollBlock/totalCount'] === 0) {
                this.isIdentifierValid = true
              }
            } else {
              // update
              if (this.$store.getters['pollBlock/totalCount'] === 0) {
                this.isIdentifierValid = true
              } else if (this.$store.getters['pollBlock/totalCount'] !== 0 && this.oldIdentifier === this.pollBlock.identifier) {
                this.isIdentifierValid = true
              }
            }
          })
      }, 500)
    }
  },
  validations: {
    pollBlock: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      identifier: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(175)
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appSelect: Select
  },
  methods: {
    getPollBlock () {
      this.$store.dispatch('pollBlock/fetchOne', this.$route.params.id)
        .then(() => {
          this.pollBlock = this.$store.getters['pollBlock/detail']
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid || !this.isIdentifierValid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('pollBlock/create', this.pollBlock)
        .then(() => {
          if (this.$store.getters['pollBlock/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
            this.$router.push('/pollBlock/' + this.pollBlock.id + '/edit')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['pollBlock/error'])
          }
        })
        .catch(error => console.log(error))
    },
    deleteRecord () {
      this.$store.dispatch('pollBlock/deleteRecord', this.pollBlock)
        .then(() => {
          if (this.$store.getters['pollBlock/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/pollBlock')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['pollBlock/error'])
          }
        })
        .catch(error => console.log(error))
    }
  }
}
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
